import * as React from "react";
import { ENQUIRY_STATE } from "../EnquiryV2Component";
import { Icon } from "@iconify/react";

const BEST_TIME_OPTIONS = [
    {
        label: "Anytime",
        value: "Anytime",
        active: true,
    },
    {
        label: "Morning",
        value: "Morning",
        active: false,
    },
    {
        label: "Afternoon",
        value: "Afternoon",
        active: false,
    },
    {
        label: "Evening",
        value: "Evening",
        active: false,
    },
];

interface IEnquiryProfileComponent {
    [key: string]: any;
}

export const EnquiryProfileComponent = (props: IEnquiryProfileComponent) => {
    const { userInfo, enquiryDetail, isLiveTourBookingAvailable } = props;
    const isLiveTourSelected = enquiryDetail.about.filter((key) => key.id === 4);
    const isLoginUser = props.isLoggedIn;
    const enquiryModalInfo = {
        step: 1,
        maxStep: 6,
        canProceed: true,
        redirectedMessage: "",
        autoCompleteData: [],
        hideAutoComplete: true,
        isEmailExists: false,
        suburbAutoCompleteInput: "",
        enquiryForm: {
            enquiryType: 1,
            parentFullName: "",
            parentFirstName: "",
            parentLastName: "",
            email: "",
            contactNumber: "",
            bestTimeToCall: "",
            suburb: "",
            password: "",
            confirmPassword: "",
            enquiryDetails: "",
            centreUserIdHash: "xwyv8",
            saveDetails: false,
        },
    };

    const [bestTimeToCallOptions, setBestTimeToCallOptions] =
        React.useState(BEST_TIME_OPTIONS);
    const [bestTimeToCall, setBestTimeToCall] = React.useState("Anytime");
    const [pageObj, setPageObj] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        timeToCall: "",
        isSaveInfoChecked: false,
    });
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorsFields, setErrorFields] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });
    React.useEffect(() => {
        setPageObj({
            ...pageObj,
            firstName: userInfo.parentFirstName,
            lastName: userInfo.parentLastName,
            email: userInfo.parentEmail,
            phone: userInfo.parentContactNumber,
        });
    }, []);

    React.useEffect(() => {
        if (Object.keys(enquiryDetail.profile).length > 1) {
            setPageObj({
                ...pageObj,
                firstName: enquiryDetail.profile.firstName,
                lastName: enquiryDetail.profile.lastName,
                email: enquiryDetail.profile.email,
                phone: enquiryDetail.profile.phone,
                timeToCall: bestTimeToCall,
                isSaveInfoChecked: enquiryDetail.profile.isSaveInfoChecked,
            });
            let timeToCallArray = BEST_TIME_OPTIONS.map((item, index) => {
                if (item.value === enquiryDetail.profile.timeToCall) {
                    return {
                        ...item,
                        active: true,
                    };
                }
                return { ...item, active: false };
            });
            setBestTimeToCallOptions(timeToCallArray);
        }
        return () => {
            setPageObj({
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                timeToCall: "",
                isSaveInfoChecked: false,
            });
        };
    }, []);

    /* 
          --------------------
          Handlers function
          ---------------------
      */
    const handlerInputChange = (e) => {
        setPageObj({
            ...pageObj,
            [e.target.name]: e.target.value,
        });
        setErrorFields({
            ...errorsFields,
            [e.target.name]: "",
        });
    };
    const onToggleTimeToCall = (item) => {
        setBestTimeToCall(item.label);
        let indexToBeUpdate = bestTimeToCallOptions.findIndex(
            (key) => key.value === item.value
        );
        let newItems = bestTimeToCallOptions.map((options, index) => {
            if (indexToBeUpdate === index) {
                setPageObj({
                    ...pageObj,
                    timeToCall: options.value,
                });
                return {
                    ...options,
                    active: true,
                };
            } else {
                return {
                    ...options,
                    active: false,
                };
            }
        });
        setBestTimeToCallOptions(newItems);
    };
    const onNext = () => {
        if (isFormValid()) {

            const isLiveTourSelected = enquiryDetail.about.filter(
                (key) => key.id === 4
            );
            let payload = {
                currentState: ENQUIRY_STATE.PROFILE,
                nextState:
                    isLiveTourSelected.length > 0 
                        ? ENQUIRY_STATE.LIVE_TOUR_CARE_OPTION
                        : pageObj.isSaveInfoChecked
                            ? ENQUIRY_STATE.CREATE_ACCOUNT
                            : ENQUIRY_STATE.LIVE_TOUR_CARE_OPTION,
                data: pageObj,
            };
            props.onNext(payload);
        }
    };

    /* 
          --------------------
          Helper function
          ---------------------
      */
    const isFormValid = () => {
        let isFormValid = true;
        let errorsFields: any = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
        };
        const { firstName, lastName, email, phone } = pageObj;
        if (firstName == null || firstName == "") {
            errorsFields["firstName"] = { message: "Please enter your frist name" };
            isFormValid = false;
        }
        if (lastName == null || lastName == "") {
            errorsFields["lastName"] = { message: "Please enter your last name" };
            isFormValid = false;
        }

        let emailPattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (
            email == null ||
            email == "" ||
            !emailPattern.test(email.toLowerCase())
        ) {
            errorsFields["email"] = { message: "Please enter a valid email address" };
            isFormValid = false;
        }

        let phonePattern = /^[0]([0-9]\s*){9}$/;
        if (phone == null || phone == "" || !phonePattern.test(phone)) {
            errorsFields["phone"] = { message: "Please enter a valid phone number" };
            isFormValid = false;
        }
        setErrorFields(errorsFields);
        return isFormValid;
    };

    return (
        <div className="enquire-form-side relative flex w-full flex-col items-center lg:max-w-320" style={{
            paddingBottom: window.CurrentUserType !== null ? '79px' : '40px'
        }}>
            <div className="step-meter bg-gray relative rounded-25">
                <span className="bg-gradient-blue absolute top-0 left-0 h-full rounded-25 w-40p"></span>
            </div>
            <div className="w-full">
                <div className="font-bold text-lg text-black-1 max-w-52">
                    Tell us about yourself
                </div>
            </div>
            <div className="grid grid-cols-2 mt-3 gap-3 w-full px-1p">
                <input
                    name="firstName"
                    placeholder="First Name*"
                    className={`enquire-input font-medium text-sm ${errorsFields.firstName ? " cp-input-validation " : ""
                        } `}
                    onChange={handlerInputChange}
                    value={pageObj.firstName}
                />
                <input
                    name="lastName"
                    placeholder="Last Name*"
                    className={`enquire-input font-medium text-sm ${errorsFields.lastName ? " cp-input-validation " : ""
                        }`}
                    onChange={handlerInputChange}
                    value={pageObj.lastName}
                />
                <div className="w-full col-span-2">
                    <input
                        name="email"
                        placeholder="Email*"
                        className={`enquire-input font-medium col-span-2 ${errorsFields.email ? " cp-input-validation " : ""
                            } `}
                        onChange={handlerInputChange}
                        value={pageObj.email}
                    />
                    {enquiryModalInfo.enquiryForm.saveDetails &&
                        enquiryModalInfo.isEmailExists && (
                            <span className="text-xs text-red-500 whitespace-nowrap">
                                User with email exists. Please login.
                            </span>
                        )}
                </div>

                <input
                    name="phone"
                    placeholder="Phone*"
                    className={`enquire-input font-medium col-span-2 ${errorsFields.phone ? " cp-input-validation " : ""
                        } `}
                    onChange={handlerInputChange}
                    value={pageObj.phone}
                />
            </div>
            {isLiveTourSelected.length == 0 ? (
                <div className="mt-6 justify-start w-full flex flex-col">
                    <span className="text-black-1 text-xs font-medium">
                        Best time to call
                    </span>
                    <div className="flex mt-2 gap-1.5">
                        {bestTimeToCallOptions.map((item, index) => {
                            return (
                                <>
                                    <div key={item.value}>
                                        <div
                                            className={`shift-pill ${item.active ? " active " : ""}`}
                                            onClick={() => onToggleTimeToCall(item)}
                                        >
                                            {item.label}
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <></>
            )}

            {/*{!isLoginUser && (*/}
            {/*    <div className="flex self-start items-center gap-1.5 text-black-2 text-xs mt-6 cursor-pointer">*/}
            {/*        <label*/}
            {/*            htmlFor="isSaveInfoChecked"*/}
            {/*            className="cursor-pointer"*/}
            {/*            onClick={() => {*/}
            {/*                setPageObj({*/}
            {/*                    ...pageObj,*/}
            {/*                    isSaveInfoChecked: !pageObj.isSaveInfoChecked,*/}
            {/*                });*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <input*/}
            {/*                type="checkbox"*/}
            {/*                name="isSaveInfoChecked"*/}
            {/*                checked={pageObj.isSaveInfoChecked}*/}
            {/*                className="cursor-pointer rounded-full text-black-2 mb-0.5 mr-1"*/}
            {/*                onChange={(e) => {*/}
            {/*                    setPageObj({*/}
            {/*                        ...pageObj,*/}
            {/*                        isSaveInfoChecked: e.target.checked,*/}
            {/*                    });*/}
            {/*                }}*/}
            {/*            />*/}
            {/*            Save your details for next time*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*)}*/}

            <div className="flex bottom-0 w-full flex-col gap-2">
                <span className="text-sm text-red-500"> {errorMessage}</span>
                <button
                    className="enquire-next-btn bg-rose hover:bg-darkrose flex items-center justify-center font-semibold text-base text-white"
                    onClick={onNext}
                    style={{ marginTop: 0 }}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default EnquiryProfileComponent;
