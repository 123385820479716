import * as React from "react";
import { Icon } from "@iconify/react";
import { ENQUIRY_STATE } from "../EnquiryV2Component";

const initialOptions = [
    {
        label: "Request a tour",
        id: 2,
        steps: 1,
        isActive: false,
        icon: "majesticons:chat-line",
    },
    {
        label: "Fees",
        id: 3,
        steps: 2,
        isActive: false,
        icon: "solar:chat-round-money-outline",
    },
    {
        label: "Availability",
        id: 1,
        steps: 3,
        isActive: false,
        icon: "ic:outline-check-circle",
    },

    {
        label: "Program",
        id: 6,
        steps: 4,
        isActive: false,
        icon: "clarity:blocks-group-line",
    },
    {
        label: "Other",
        id: 5,
        steps: 5,
        isActive: false,
        icon: "ant-design:question-circle-outlined",
    },
];

const LIVE_TOUR = {
    label: "Live Tour Booking",
    id: 4,
    steps: 3,
    isActive: false,
    icon: "material-symbols:tour-outline",
};

interface IEnquiryAboutProps {
    [key: string]: any;
}

function EnquiryAbout(props: IEnquiryAboutProps) {
    const { isLiveTourBookingAvailable, enquiryDetail } = props;
    const [items, setItems] = React.useState(JSON.parse(JSON.stringify(initialOptions)));
    const [errorMessage, setErrorMessage] = React.useState("")

    /* 
      --------------------
      Hooks
      ---------------------
    */
    React.useEffect(() => {
        if (isLiveTourBookingAvailable) {
            let tourObj = JSON.parse(JSON.stringify(LIVE_TOUR))
            setItems([tourObj, ...items])

        }
        if (enquiryDetail?.about?.length > 0) {
            setStateFromProps()
        }
        return () => {
            console.log('un mounted about component');
            setItems([...initialOptions])
        }
    }, [])

    const setStateFromProps = () => {
        let tourObj = JSON.parse(JSON.stringify(LIVE_TOUR))
        const clonedItems = isLiveTourBookingAvailable ? [tourObj, ...items] : [...items];
        enquiryDetail.about.map((item, i) => {
            let indexToBeUpdate = clonedItems.findIndex(key => key.id === item.id)
            clonedItems[indexToBeUpdate].isActive = item.isActive;
        })
        setItems(clonedItems)
    }


    /* 
      --------------------
      Handlers function
      ---------------------
    */
    const onItemClick = (id: number) => {
        const clonedItems = [...items];
        let index = clonedItems.findIndex((key) => key.id === id);
        clonedItems[index].isActive = !clonedItems[index].isActive;
        setErrorMessage('')
        setItems(clonedItems);
    };

    const onNext = () => {
        if (isItemSelected()) {
            let selectedOptions = items.filter(item => item.isActive)
            let payload = {
                currentState: ENQUIRY_STATE.ABOUT,
                nextState: ENQUIRY_STATE.PROFILE,
                data: selectedOptions
            }
            props.onNext(payload);
        } else {
            setErrorMessage('Please select any one option.')
        }
    };

    /* 
    --------------------
    Handlers function
    ---------------------
    */
    const isItemSelected = () => {
        return items.some(key => key.isActive)
    }
    // console.log('Items=',items);

    return (
        <div className="flex flex-col relative items-center w-full lg:max-w-320 enquire-form-side">
            <div className="step-meter bg-gray relative rounded-25">
                <span className="bg-gradient-blue absolute top-0 left-0 h-full rounded-25 w-20p"></span>
            </div>
            <div className="w-full">
                <div className="font-bold text-lg text-black-1 leading-19p max-w-50">
                    What would you like to enquire about?
                </div>
            </div>
            <div className="grid grid-cols-2 mt-3 gap-2.5 w-full">
                {items.map((item, index) => {
                    return (
                        <div
                            className={`enquire-type-card flex flex-col gap-2 items-center justify-center ${item.isActive ? " bg-sky-300 " : " bg-light-200 "
                                }`}
                            onClick={() => onItemClick(item.id)}
                            key={item.id}
                        >
                            <Icon icon={item.icon} className="text-berry text-1xl"></Icon>
                            <span className="text-black-1 text-xs font-bold">
                                {item.label}
                            </span>
                        </div>
                    );
                })}
            </div>
            <div className="flex bottom-0 w-full flex-col gap-2">
                <span className="text-sm text-red-500"> {errorMessage}</span>
                <button
                    className="enquire-next-btn bg-rose hover:bg-darkrose flex items-center justify-center font-semibold text-base text-white"
                    onClick={onNext}
                    style={{ marginTop: 0 }}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default EnquiryAbout;
