
import * as React from "react";
import { Icon } from '@iconify/react';
import { ENQUIRY_STATE } from "../EnquiryV2Component";
import axios from "axios";
interface IEnquiryFormSubmitComponent {
    [key: string]: any
}

/* 
    --------------------
      Handlers function
    ---------------------
  */



export const EnquiryFormSubmitComponent = (props: IEnquiryFormSubmitComponent) => {
    const { initialCareCentre, careCentres, enquiryDetail } = props;
    var activeModal = ""
    const isPaidCentre = initialCareCentre.isPaidCentre;
    const [isCentreNearbyProcessed, setIsCentreNearbyProceessed] = React.useState(false)
    const [pageStack, setPageStack] = React.useState([]);
    React.useEffect(() => {
        if (props.careCentres?.length > 0) {
            setIsCentreNearbyProceessed(true);
        }
    }, [props])

    const showCentreOptions = () => {

    }


    const checkEmailInvalid = () => {
        let formData = new FormData();
        formData.append('email', enquiryDetail.profile.email);


        fetch(`/parentaccount/validateemail`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (!data) {
                    props.onNext({
                        currentState: ENQUIRY_STATE.SHARE,
                        nextState: ENQUIRY_STATE.SAVE_DETAILS,
                    })
                }
                else {
                    const currentURL = window.location.href;
                    const containsChildCareProviders = currentURL.includes("/childcareproviders");
                    //let url = new URL(window.location.href);
                    //let params = new URLSearchParams(url.search);

                    //params.append('enquiry_id', props.enquiryDetail.formSubmitData.enquiryId);
                    //window.history.replaceState({}, '', `${url.pathname}?${params}`);

                    localStorage.setItem('enquiry_type', props.enquiryType);
                    localStorage.setItem('enquiry_id', props.enquiryDetail.formSubmitData.enquiryId);

                    if (containsChildCareProviders) {

                        window.open(`${window.BaseUrl}/signin`, '_blank');
                    } else {

                        if (pageStack.length <= 0) {

                            setPageStack(prevStack => [...prevStack, "emailPage"]);
                        }

                        const authModal = document.getElementById('authModal');
                        if (authModal) {
                            authModal.classList.remove('hidden');
                        }
                    }
                }

            })
            .catch(error => {
                console.error(error);
            })
    }


    return (
        <>
            <style>
                {
                    `
                        .ico-check {
                            margin-right: auto;
                        }
                        @media screen and (max-width: 992px) {
                            .ico-check {
                                margin: auto;
                            }
                        }
                        
                    `
                }
            </style>
            {props.enabledDoorDash ?
                <div className="flex flex-col items-center w-full lg:max-w-320 enquire-form-side">
                    <div className="h-full w-full flex flex-col lg:justify-center items-center gap-2">
                        <div className="mt-5 lg:mt-0">
                            <Icon icon="teenyicons:tick-circle-solid"
                                className="text-3xl text-darkgreen ico-check mt-[15px]"></Icon>
                            <h2 className="text-[22px] text-center font-bold text-black ml-1 lg:ml-0 mb-0">
                                Congrats, your enquiry <br className="hidden lg:block" />has been
                                <span className="font-bold">
                                    {' '} submitted!
                                </span>
                            </h2>
                        </div>

                        {/* <span className="text-sm text-black-2">{initialCareCentre.name} typically responds within <span className="text-berry font-semibold"> few hours</span></span> */}
                        {
                            activeModal == 'tourBooking' &&
                            <div className="center-detail-card flex flex-col gap-2">
                                <div className="text-black-1 font-semibold text-sm">
                                    <span x-text="initialCareCenter.name"></span>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Icon icon="ci:calendar-days"
                                        className="text-sm text-berry"></Icon>
                                    <span className="text-xs text-black-2" x-text="liveTourModalInfo.tourDate"></span>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Icon icon="mdi:clock-outline"
                                        className="text-sm text-berry"></Icon>
                                    <span className="text-xs text-black-2" x-text="liveTourModalInfo.tourTime"></span>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Icon icon="material-symbols:location-on-outline"
                                        className="text-sm text-berry"></Icon>
                                    <span className="text-xs text-black-2" x-text="initialCareCenter.address"></span>
                                </div>
                            </div>
                        }
                        <div className="flex flex-col gap-2.5 mt-2 lg:mt-8">
                            {
                                !isCentreNearbyProcessed && careCentres && careCentres.length > 0 && !isPaidCentre &&
                                <button className=" enquire-next-btn bg-light-200 flex gap-1.5 items-center justify-center font-medium text-xs text-black" onClick={showCentreOptions}
                                    style={{ marginTop: "0px" }}>
                                    <Icon icon="ic:baseline-verified"
                                        className="text-berry text-xl"></Icon>
                                    Enquire at Verified Centres nearby
                                </button>
                            }



                            <a href="/child-care-subsidy-calculator"
                                className="enquire-next-btn flex gap-1.5 items-center justify-center font-bold text-[16px] font-manrope text-[#5A60EC]"
                                style={{ marginTop: "0px", borderRadius: '100px', padding: '12px 25px', border: '1.71px solid #5A60EC', height: '100%' }}>
                                <Icon icon="ph:calculator"
                                    className="text-berry text-xl"></Icon>
                                Calculate Childcare Subsidy
                            </a>
                            {!props.isLoggedIn && <button
                                onClick={() => checkEmailInvalid()}
                                className="enquire-next-btn flex gap-1.5 items-center justify-center font-bold text-[16px] font-manrope text-white"
                                style={{ marginTop: "0px", borderRadius: '100px', padding: '12px 25px', height: '100%', backgroundColor: '#FF5A9A' }}>
                                Save your details
                            </button>}


                        </div>
                    </div>
                </div>
                : <div className="flex flex-col items-center w-full lg:max-w-320 enquire-form-side">
                    <div className="h-full w-full flex flex-col justify-center gap-2">
                        <Icon icon="teenyicons:tick-circle-solid"
                            className="text-3xl text-darkgreen mx-auto mt-[15px]"></Icon>
                        <h2 className="text-[22px] text-center font-bold text-black ml-1 lg:ml-0 mb-0">
                            Congrats, your enquiry <br className="hidden lg:block" />has been
                            <span className="font-bold">
                                {' '} submitted!
                            </span>
                        </h2>
                        {/* <span className="text-sm text-black-2">
                            {initialCareCentre.name} typically responds within
                            <span className="text-berry font-semibold"> few hours</span>
                        </span> */}
                        {
                            activeModal == 'tourBooking' &&
                            <div className="center-detail-card flex flex-col gap-2">
                                <div className="text-black-1 font-semibold text-sm">
                                    <span x-text="initialCareCenter.name"></span>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Icon icon="ci:calendar-days"
                                        className="text-sm text-berry"></Icon>
                                    <span className="text-xs text-black-2" x-text="liveTourModalInfo.tourDate"></span>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Icon icon="mdi:clock-outline"
                                        className="text-sm text-berry"></Icon>
                                    <span className="text-xs text-black-2" x-text="liveTourModalInfo.tourTime"></span>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Icon icon="material-symbols:location-on-outline"
                                        className="text-sm text-berry"></Icon>
                                    <span className="text-xs text-black-2" x-text="initialCareCenter.address"></span>
                                </div>
                            </div>
                        }
                        <div className="flex flex-col gap-2.5 mt-8">
                            {
                                !isCentreNearbyProcessed && careCentres && careCentres.length > 0 && !isPaidCentre &&
                                <button className=" enquire-next-btn bg-light-200 flex gap-1.5 items-center justify-center font-medium text-xs text-black" onClick={showCentreOptions}
                                    style={{ marginTop: "0px" }}>
                                    <Icon icon="ic:baseline-verified"
                                        className="text-berry text-xl"></Icon>
                                    Enquire at Verified Centres nearby
                                </button>
                            }

                            <a href="/child-care-subsidy-calculator"
                                className="enquire-next-btn flex gap-1.5 items-center justify-center font-bold text-[16px] font-manrope text-[#5A60EC]"
                                style={{ marginTop: "0px", borderRadius: '100px', padding: '12px 25px', border: '1.71px solid #5A60EC', height: '100%' }}>
                                <Icon icon="ph:calculator"
                                    className="text-berry text-xl"></Icon>
                                Calculate Childcare Subsidy
                            </a>
                            {!props.isLoggedIn && <button
                                onClick={() => checkEmailInvalid()}
                                className="enquire-next-btn flex gap-1.5 items-center justify-center font-bold text-[16px] font-manrope text-white"
                                style={{ marginTop: "0px", borderRadius: '100px', padding: '12px 25px', height: '100%', backgroundColor: '#FF5A9A' }}>
                                Save your details
                            </button>}

                        </div>
                    </div>
                </div>}

        </>


    );
}

export default EnquiryFormSubmitComponent;