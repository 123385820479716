import * as React from "react";
import { Icon } from "@iconify/react";
import { ENQUIRY_STATE } from "../EnquiryV2Component";
import axios from "axios";
import { getTourAvailabilities } from "../service/LiveTourService";
interface IEnquiryCareOptionComponent {
    [key: string]: any;
}

const CARE_DETAIL = () => {
    return {
        active: true,
        title: "Child",
        birthday: { day: "", month: "", year: "" },
        careDays: [
            { label: "Mon", value: "2", active: true },
            { label: "Tue", value: "3", active: false },
            { label: "Wed", value: "4", active: false },
            { label: "Thu", value: "5", active: false },
            { label: "Fri", value: "6", active: false },
            { label: "Sat", value: "7", active: false },
            { label: "Sun", value: "8", active: false },
        ],
        overnightEvening: false,
        startDate: [
            {
                label: "ASAP",
                value: "ASAP",
                startDate: new Date(),
                endDate: new Date().setDate(new Date().getDate() + 7),
                active: true,
            },
            {
                label: "In the next 30 days",
                value: "30d",
                startDate: new Date(),
                endDate: new Date().setDate(new Date().getDate() + 30),
                active: false,
            },
            {
                label: "3 months",
                value: "3m",
                startDate: new Date(),
                endDate: new Date().setMonth(new Date().getMonth() + 3),
                active: false,
            },
            {
                label: "6 months",
                value: "6m",
                startDate: new Date(),
                endDate: new Date().setMonth(new Date().getMonth() + 6),
                active: false,
            },
            {
                label: "12 months",
                value: "12m",
                startDate: new Date(),
                endDate: new Date().setMonth(new Date().getMonth() + 12),
                active: false,
            },
        ],
    };
};

const initalLiveTourModalInfo = {
    careDetails: [CARE_DETAIL()],
    isDateTimeApiLoading: false,
};

export const EnquiryCareOptionComponent = (
    props: IEnquiryCareOptionComponent
) => {
    const { token, enquiryDetail } = props;
    const { profile, liveTourAvailData } = enquiryDetail;
    const [liveTourModalInfoData, setLiveTourModalInfo] = React.useState(
        JSON.parse(JSON.stringify(initalLiveTourModalInfo))
    );
    const [errorFields, setErrorFields] = React.useState<any>({});
    const [loader, setLoader] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");


    React.useEffect(() => {
        setLiveTourModalInfo(() => {
            return {
                careDetails: liveTourAvailData.liveTourModalInfoData?.careDetails || [CARE_DETAIL()]
            }
        })

    }, [])

    /* 
        --------------------
        Handlers function
        ---------------------
     */

    const handlerInputChange = (e, index) => {
        let updatedData = { ...liveTourModalInfoData };
        updatedData.careDetails[index].birthday[e.target.name] = e.target.value;
        setLiveTourModalInfo(updatedData);
        //setErrorFields({
        //    ...errorFields,
        //    [`${e.target.name}${index}`]: "",
        //});
    };

    const onSelectChildChip = index => {
        let updatedData = { ...liveTourModalInfoData };
        updatedData.careDetails.forEach((item, idx) => {
            if (!updatedData.careDetails[index]) {
                let nearestIndex = index - 1;
                updatedData.careDetails[nearestIndex].active = true;

            }
            else {
                item.active = index === idx ? true : false;
            }
        });
        setLiveTourModalInfo(updatedData);
    };

    const onDeleteChild = (index) => {
        let updatedData = { ...liveTourModalInfoData };
        updatedData.careDetails.splice(index, 1);
        setLiveTourModalInfo(updatedData);
    };

    const onAddNewChild = () => {
        let updatedData = { ...liveTourModalInfoData };
        updatedData.careDetails.map((item) => {
            item.active = false;
        });
        updatedData.careDetails.push(CARE_DETAIL());
        setLiveTourModalInfo(updatedData);
    };

    const onSelectCareDay = (careDetailIndex, careDayIndex) => {
        let updatedData = { ...liveTourModalInfoData };
        let careDayData =
            updatedData.careDetails[careDetailIndex].careDays[careDayIndex];
        careDayData.active = !careDayData.active;
        setErrorFields({
            ...errorFields,
            days: "",
        });
        setLiveTourModalInfo(updatedData);
    };

    const onSelectStartDate = (careDetailIndex, careDayIndex) => {
        let updatedData = { ...liveTourModalInfoData };

        const { careDetails } = updatedData;
        const careDetail = careDetails[careDetailIndex];


        const startDate = { ...careDetail.startDate[careDayIndex], active: !careDetail.startDate[careDayIndex].active };

        const newStartDateArray = careDetail.startDate.map((date, index) => ({
            ...date,
            active: index === careDayIndex ? startDate.active : false,
        }));

        updatedData.careDetails[careDetailIndex] = {
            ...careDetail,
            startDate: newStartDateArray,
        };

        //let startDateData =
        //    updatedData.careDetails[careDetailIndex].startDate[careDayIndex];
        //startDateData.active = !startDateData.active;


        setErrorFields({
            ...errorFields,
            startDate: "",
        });
        setLiveTourModalInfo(updatedData);
    };

    const onSelectOvernight = (index) => {
        let updatedData = { ...liveTourModalInfoData };
        updatedData.careDetails[index].overnightEvening =
            !updatedData.careDetails[index].overnightEvening;
        setLiveTourModalInfo(updatedData);
    };

    const onNext = () => {
        if (isFormValid()) {
            //should do api call here

            setLoader(true);
            let selectedDate = liveTourModalInfoData.careDetails[0]?.startDate.find(
                (key) => key.active
            );
            let formattedStartDate = new Date(selectedDate.startDate)
                ?.toISOString()
                .split("T")[0];


            let formattedEndDate = new Date(selectedDate.endDate)
                .toISOString()
                .split("T")[0];

            const isNotLiveTourSelected = enquiryDetail.about.filter(
                (key) => key.id !== 4
            );

            try {

                let childrenDetails = [];
                liveTourModalInfoData.careDetails.map((careDetail) => {
                    let childObj = {};
                    childObj["name"] = `${profile.firstName} ${profile.lastName}`;
                    childObj["birthday"] = `${careDetail.birthday.year}-${careDetail.birthday.month < 10 ? "0" : ""
                        }${parseInt(careDetail.birthday.month, 10).toString()}-${careDetail.birthday.day < 10 ? "0" : ""
                        }${parseInt(careDetail.birthday.day, 10).toString()}`;
                    childObj["careStartDate"] = formattedStartDate;
                    childObj["selectedCareDays"] = [];
                    careDetail.careDays.map((careDay) => {
                        if (careDay.active)
                            childObj["selectedCareDays"].push(careDay.value);
                    });
                    childObj["overnightCareRequired"] = careDetail.overnightEvening;
                    childrenDetails.push(childObj);
                });

                if (isNotLiveTourSelected.length > 0) {
                    setLoader(false);
                    let payload = {
                        currentState: ENQUIRY_STATE.LIVE_TOUR_CARE_OPTION,
                        nextState: ENQUIRY_STATE.COMMENTS,
                        data: {
                            startDate: formattedStartDate,
                            endDate: formattedEndDate,
                            tourDate: "",
                            tourTime: "",
                            isDateTimeApiLoading: false,
                            isBackDateTimeExists: false,
                            availabilityDetailsResponse: [],
                            liveTourModalInfoData,
                            availabilityDetails: [],
                            childrenDetails: childrenDetails,
                        },
                    };
                    props.onNext(payload);
                }
                else {
                    getTourAvailabilities(formattedStartDate, formattedEndDate, token).then(
                        (res: any) => {
                            if (res.data?.isSuccess) {
                                setLoader(false);
                                let payload = {
                                    currentState: ENQUIRY_STATE.LIVE_TOUR_CARE_OPTION,
                                    nextState: ENQUIRY_STATE.LIVE_TOUR_DATE_OPTION,
                                    data: {
                                        startDate: formattedStartDate,
                                        endDate: formattedEndDate,
                                        tourDate: "",
                                        tourTime: "",
                                        isDateTimeApiLoading: false,
                                        isBackDateTimeExists: false,
                                        availabilityDetailsResponse: res.data.availabilityDetails,
                                        liveTourModalInfoData,
                                        availabilityDetails: [],
                                        childrenDetails: childrenDetails,
                                    },
                                };
                                props.onNext(payload);
                            } else {
                                let message = res.data.errors[0];
                                setErrorMessage(message);
                                setLoader(false);
                            }
                        }
                    );
                }



            } catch (error) { }
        } else {
            console.log(errorFields);
        }
    };

    /* 
          --------------------
          Helper function
          ---------------------
    */
    const isFormValid = () => {
        let isValidData = true;
        let errorsFieldsObj: any = {};

        const isLeapYear = (year) => {
            return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        };

        const daysInMonth = (month, year) => {
            return [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1];
        };

        const isValidNumber = (value) => {
            return /^\d+$/.test(value); // Check if the value contains only digits
        };

        liveTourModalInfoData.careDetails.map((careDetail, index) => {
            if (!careDetail.careDays.some((key) => key.active)) {
                errorsFieldsObj["days"] = { message: "Select Day" };
                isValidData = false;
            }

            if (!careDetail.startDate.some((key) => key.active)) {
                errorsFieldsObj["startDate"] = { message: "Select Start Date" };
                isValidData = false;
            }

            const { day, month, year } = careDetail.birthday;

            // Validate day
            if (
                day === "" ||
                !isValidNumber(day) ||
                day < 1 ||
                day > daysInMonth(month, year)
            ) {
                errorsFieldsObj[`day${index}`] = { message: "Incorrect Day" };
                isValidData = false;
            }

            // Validate month
            if (
                month === "" ||
                !isValidNumber(month) ||
                month < 1 ||
                month > 12
            ) {
                errorsFieldsObj[`month${index}`] = { message: "Incorrect Month" };
                isValidData = false;
            }

            // Validate year
            if (
                year === "" ||
                !isValidNumber(year) ||
                year < 1900 ||
                year > new Date().getFullYear()
            ) {
                errorsFieldsObj[`year${index}`] = { message: "Incorrect Year" };
                isValidData = false;
            }
        });

        setErrorFields(errorsFieldsObj);
        return isValidData;
    };

    return (
        <div className="enquire-form-side relative flex w-full flex-col items-center pt-3 lg:max-w-320 lg:pt-0" style={{
            paddingBottom: window.CurrentUserType !== null ? '79px' : '40px'
        }}>
            <div className="flex w-full items-baseline justify-center gap-5 pr-4">
                <div
                    className="step-meter bg-gray rounded-25 relative"
                    style={{ marginBottom: "25px" }}
                >
                    <span className="bg-gradient-blue rounded-25 w-60p absolute left-0 top-0 h-full"></span>
                </div>
            </div>
            <div className="flex flex-col justify-between gap-3 overflow-y-auto pb-4">
                {Object.keys(errorFields).length > 0 && (
                    <p id="signupValidationError" className="mt-2 font-medium text-[#ED033B]">
                        Some fields have invalid data. Please check that entries match the required format.
                    </p>
                )}

                <div>
                    <h3 className="text-black-1 text-lg font-bold">
                        What care do you need?
                    </h3>
                    <div className="mt-2 flex flex-wrap gap-1">
                        {liveTourModalInfoData.careDetails.map(function (item, i) {
                            return (
                                <div key={i}>
                                    <span
                                        className={`shift-pill ${item.active ? "active" : ""}`}
                                        onClick={() => onSelectChildChip(i)}
                                        style={{ position: "relative" }}
                                    >
                                        {`${item.title} ${i + 1}`}
                                        {/*{i > 0 ? <span className="text-sm" onClick={onDeleteChild}>x</span> : <></>} */}
                                        {i > 0 ? <span style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: '-3px',
                                            width: '15px',
                                            background: '#fff',
                                            borderRadius: '50%',
                                            padding: '5px'
                                        }} onClick={() => onDeleteChild(i)}>
                                            <img src="/img/icons/close-icon.svg" />
                                        </span> : <span></span>}
                                    </span>
                                </div>
                            );
                        })}
                        {liveTourModalInfoData.careDetails.length < 5 && (
                            <span
                                className="text-black-3 bg-black-3 care-add-btn flex cursor-pointer items-center justify-center rounded-full text-2xl"
                                onClick={onAddNewChild}
                            >
                                +
                            </span>
                        )}
                    </div>
                </div>
                {liveTourModalInfoData.careDetails.map(function (
                    item,
                    careDetailIndex
                ) {
                    return (
                        <React.Fragment key={careDetailIndex}>
                            {item.active && (
                                <div className="flex flex-col gap-3">
                                    <div>
                                        <div className="text-black-1 text-xs font-semibold">
                                            Child's birthday
                                        </div>
                                        <div className="grid-cols-3 px-1p mt-1 grid gap-3">
                                            <input
                                                name="day"
                                                placeholder="Day"
                                                value={item.birthday.day}
                                                onChange={(e) => handlerInputChange(e, careDetailIndex)}
                                                className={`enquire-input font-medium text-sm ${errorFields[`day${careDetailIndex}`]
                                                    ? " cp-input-validation "
                                                    : ""
                                                    }`}
                                            />

                                            <input
                                                name="month"
                                                placeholder="Month"
                                                value={item.birthday.month}
                                                onChange={(e) => handlerInputChange(e, careDetailIndex)}
                                                className={`enquire-input font-medium text-sm ${errorFields[`month${careDetailIndex}`]
                                                    ? " cp-input-validation "
                                                    : ""
                                                    }`}
                                            />
                                            <input
                                                name="year"
                                                placeholder="Year"
                                                value={item.birthday.year}
                                                onChange={(e) => handlerInputChange(e, careDetailIndex)}
                                                className={`enquire-input font-medium text-sm ${errorFields[`year${careDetailIndex}`]
                                                    ? " cp-input-validation "
                                                    : ""
                                                    }`}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="text-black-1 text-xs font-semibold">
                                            Care Days
                                        </div>
                                        <div className="mt-2 flex flex-wrap gap-1">
                                            {item.careDays.map(function (careDay, i) {
                                                return (
                                                    <div key={i}>
                                                        <span
                                                            className={`shift-pill-sm ${errorFields["days"]
                                                                ? " cp-input-validation"
                                                                : " "
                                                                } ${careDay.active ? "active" : ""}`}
                                                            onClick={() =>
                                                                onSelectCareDay(careDetailIndex, i)
                                                            }
                                                        >
                                                            {careDay.label}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div
                                            className={`shift-pill-sm mt-2 gap-1 ${item.overnightEvening ? "active" : ""
                                                }`}
                                            onClick={() => onSelectOvernight(careDetailIndex)}
                                        >
                                            <Icon icon="ph:moon" className="text-sm"></Icon>Overnight
                                            / evening
                                        </div>
                                    </div>

                                    <div>
                                        <div className="text-black-1 text-xs font-semibold">
                                            Start Date
                                        </div>
                                        <div className="mt-2 flex flex-wrap gap-x-1 gap-y-2">
                                            {item.startDate.map(function (date, i) {
                                                return (
                                                    <div key={i}>
                                                        <span
                                                            className={`shift-pill-sm ${errorFields["startDate"]
                                                                ? " cp-input-validation"
                                                                : " "
                                                                } ${date.active ? "active" : " "} `}
                                                            onClick={() =>
                                                                onSelectStartDate(careDetailIndex, i)

                                                            }
                                                        >
                                                            {date.label}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
            {/*<button*/}
            {/*    className="enquire-next-btn bg-rose bottom-0 mt-5 flex items-center justify-center text-base font-semibold text-white"*/}
            {/*    onClick={onNext}*/}
            {/*    style={{ marginTop: "11px" }}*/}
            {/*>*/}
            {/*    {loader ? "Loading ..." : "Next"}*/}
            {/*</button>*/}
            <div className="bottom-0 flex w-full flex-col gap-2">
                <span className="text-sm text-red-500"> {errorMessage}</span>
                <button
                    className="enquire-next-btn bg-rose bottom-0 mt-5 flex items-center justify-center text-base font-semibold text-white"
                    onClick={onNext}
                    style={{ marginTop: 0 }}
                >
                    {loader ? "Loading ..." : "Next"}
                </button>
            </div>
        </div >
    );
};

export default EnquiryCareOptionComponent;
